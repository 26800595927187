import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import "./Header.scss";
import $ from 'jquery';
import { useTransition, animated as a } from '@react-spring/web'
import { useTrail } from '@react-spring/web'
import {useCallback, useEffect, useRef, useState} from "react";
import useFetch from "../../hooks/useFetch";
import {parseImage} from "../../script/Utils";
const Header = (props) => {

    const { data, loading, error } = useFetch( `/api/header?populate=*` );

    const [currentSlide, setCurrentSlide] = useState(0);

    const items = (data) ? data.attributes.title.split("\n") : [];
    const config = { mass: 2, tension: 100, friction: 30 };
    const [toggle, set] = useState(true);
    const trail = useTrail(items.length, {
        config,
        opacity: toggle ? 1 : 0,
        x: toggle ? 0 : 20,
        height: toggle ? 'auto' : 0,
        from: { opacity: 0, x: 20, height: 0 }
    });


    useEffect(() => {

    }, [])
    useEffect(() => {
        if(data){
            props.incrementReady();
            console.log("api called");
        }
    },[data])

    return (
        <div className="header">

            <canvas id={"particle-canvas"}></canvas>
            <div className="container mx-auto navbar-padding flex flex-column justify-center relative">
                <div className="content text-center">
                    {trail.map(({ x, height, ...rest }, index) => (
                        <a.div
                            key={items[index]}
                            className="trails-text"
                            style={{
                                ...rest,
                                transform: x.interpolate(x => `translate3d(0,${x}px,0)`)
                            }}
                        >
                            <a.div style={{ height }}><p className={"text-l yellow-text font-bold"}>{items[index]}</p></a.div>
                        </a.div>
                    ))}
                    <img src={"img/gradient_pattern.png"} className={"background-pattern rotating"}/>
                    <img className={"pt-4 h-full float"} id={"mobile-mockup"} src={(data) ? parseImage(data.attributes.floating_image) : ""}/>

                </div>

            </div>
        </div>
    );
};

export default Header;
