import React from 'react'

import PostCard from "../Post/PostCard";

export default function BlogList({ posts }) {
  return (
    <div>
      <div className="section-padding-sm">
        <div className={"background-offset"}></div>
        <div className="container relative mx-auto navbar-padding flex flex-wrap justify-center items-center items-stretch">
        {!posts && 'You must add at least one Post'}
        {posts &&
            posts.map((post) => {
                return (


                        <div className={"mx-auto text-center flex sm:w-1/2 md:w-1/3 lg:w-1/3 "}>
                          <div key={post.id} className={"postcard-wrapper"}>
                            <PostCard post={post} />
                          </div>
                        </div>

                );
            })}

        </div>
      </div>
    </div>
  );
}
