import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import {persistor, store} from "./redux/store";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import { ParallaxProvider } from "react-scroll-parallax";
console.log("React"+React);
console.log("ReactDOM"+ReactDOM);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

        <ParallaxProvider>
        <Provider store={store}>

                <App/>

        </Provider>
        </ParallaxProvider>

);


const appHeight = () => {
    const doc = document.documentElement
    let recommendedHeight = window.document.documentElement.clientHeight;
    doc.style.setProperty('--app-height', `${recommendedHeight}px`)
    console.log("space available outer: " + recommendedHeight);
}


appHeight();
window.addEventListener('resize', appHeight);

