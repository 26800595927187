
import "./Loading.scss";
import {useEffect, useState} from "react";

const Loading = (props) => {


    const [isLoading, setIsLoading] = useState(true);
    const handleLoading = () => {
        console.log("content loaded!!");
        setIsLoading(false);
    }


    useEffect(() => {
        if(props.loading != null) {
            setIsLoading(props.loading)
        }
    }, [props.loading])
    //
    useEffect(()=>{
        window.addEventListener("load",handleLoading);
        return () => window.removeEventListener("load",handleLoading);
    },[])



    return (
        <div className={"loading pointer-events-none " + (isLoading ? "opacity-1" : "opacity-0")} id={"loading-screen"}>
            <div className="container mx-auto flex flex-column justify-center relative items-center">
                <div className="content text-center">
                    <img style={{width:"80px"}} src={"img/SpinBlue.gif"}/>
                </div>
            </div>
        </div>
    );
};

export default Loading;
