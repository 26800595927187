import React, {useState} from "react";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import "./Testimonials.scss";
import {useEffect} from 'react';
import {animated as a, useInView, useSpring} from "@react-spring/web";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const Testimonials = (props) => {

    const onSubmit = (e) => {
        e.preventDefault();
        console.log("refresh prevented");
    };
    let testimonialsList = props.data
    if (!Array.isArray(testimonialsList)) {
        testimonialsList = [];
    }


    const [ref2, isInView2] = useInView(
        {
            rootMargin: '-40% 0%',
            amount: 0,
        }
    )

    const springChat1 = useSpring(
        isInView2 ? {
            from: {
                scale: 0
            },
            to: {
                scale: 1
            },
            config: {
                tension: 100
            }, delay: 200
        } : {
            from: {
                scale: 0
            }, delay: 200
        })
    const springs3 = useSpring(
        isInView2 ? {
            from: {
                opacity: 0,
                y: 100,
            },
            to: {
                opacity: 1,
                y: 0,
            },
            config: {
                tension: 100
            },
        } : {
            from: {
                opacity: 0,
                y: 100,

            }
        })
    const springChat2 = useSpring(
        isInView2 ? {
            from: {
                scale: 0
            },
            to: {
                scale: 1
            },
            config: {
                tension: 100
            }, delay: 800
        } : {
            from: {
                scale: 0

            }, delay: 3000
        })


    useEffect(() => {

// stop animation after 3 seconds


    }, []);

    return (
        <div ref={ref2} className="testimonials section-padding text-center ">
            <div className={"background-offset"}></div>

            <a.div style={springs3} className={"container"}>
                <h2 className={"title text-2xl relative font-bold mb-3 text-white my-5 md:my-0 pb-4"}>Read What
                    Our{"\n"}Customers Say</h2>

                <div className={"flex relative md:flex-row flex-col"}>
                    {testimonialsList.map((testimonial, index) => (
                        <div key={testimonial.id} className={"flex-1 content-section"}>
                            <div className="item">
                                <div className="profile">
                                    <img
                                        src={testimonial.attributes.user_avatar.data.attributes.url}
                                        alt=""/>
                                    <div className="information">
                                        <div className="stars">
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                        </div>
                                        <p>{testimonial.attributes.user_full_name}</p>
                                        <span>{testimonial.attributes?.user_title}</span>
                                    </div>

                                </div>
                                <p>{testimonial.attributes.description}</p>
                                <div className="icon">

                                    {/*<FontAwesomeIcon icon="fa-solid fa-quote-left" />*/}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </a.div>
        </div>
    );
};

export default Testimonials;
