import React, {useState} from "react";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import "./Experience.scss";
import {useEffect} from 'react';
import {animated as a, useInView, useSpring} from "@react-spring/web";
import { useParallax } from "react-scroll-parallax";



const Experience = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const onSubmit = (e) => {
        e.preventDefault();
        console.log("refresh prevented");
    };

    const parallax = useParallax({
        translateY: [0, 15],
    });
    const parallax2 = useParallax({
        translateY: [0, -15],
    });

    const [ref, isInView] = useInView(
        {
            rootMargin: '-40% 0%',
            once : true
        }
    )
    const [ref2, isInView2] = useInView(
        {
            rootMargin: '-40% 0%',
            once : true
        }
    )
    const [refText, isInViewText] = useInView(
        {
            rootMargin: '-40% 0%',
            once : true
        }
    )

    const springs = useSpring(
        isInView ? {
            from: {
                opacity: 0,
                y: -100,
            },
            to: {
                opacity: 1,
                y: 50,
            },
            config: {
                tension:100
            },    delay:100
        }: {
            from: {
                opacity: 0,
                y: 100,

            },    delay:1000
        })
    const springs2 = useSpring(
        isInView ? {
            from: {
                opacity: 0,
                y: 100,
            },
            to: {
                opacity: 1,
                y: 0,
            },
            config: {
                tension:100
            },    delay:100
        }: {
            from: {
                opacity: 0,
                y: 100,

            },
        })
    const springs2text = useSpring(
        isInView ? {
            from: {
                opacity: 0,
                y: 100,
            },
            to: {
                opacity: 1,
                y: 0,
            },
            config: {
                tension:100
            },    delay:100
        }: {
            from: {
                opacity: 0,
                y: 100,

            },
        })
    const springs3 = useSpring(
        isInView2 ? {
            from: {
                opacity: 0,
                y: 100,
            },
            to: {
                opacity: 1,
                y: 0,
            },
            config: {
                tension:100
            },
        }: {
            from: {
                opacity: 0,
                y: 100,

            }
        })

    return (
        <div ref={refText} className="experience section-padding-sm">
            <div className={"background-offset"}></div>
            <div className="container relative mx-auto navbar-padding flex flex-col justify-center items-center">
                <div className={"mx-auto w-full text-center flex flex-col md:flex-row "}>

                    <div  className={"w-full flex-1 text-left flex flex-col align-center justify-center  pb-12 md:pb-0"}>
                        <a.h2 style={springs2text} className={"title text-2xl font-bold mb-3 text-white"}>Maximize Your Savings Potential</a.h2>
                        <a.div style={springs2text} className={"xfi-border "}></a.div>
                        <a.p style={springs2text} className={"mt-3 text-white"}>Stay on top with real-time
                            currency tracking.</a.p>
                    </div>

                    <div ref={ref} className={"image-section flex-1 relative w-100"}>
                        <img ref={parallax.ref}  className={"relative app-mockup"} src={"img/app-mockup-6.png"}></img>
                        <div id={"mockup-base"}>
                            <img ref={parallax2.ref} src={"img/app-mockup-5.png"}></img>
                        </div>

                    </div>


                </div>


            </div>

            <div className="container relative mx-auto navbar-padding flex  md:flex-col justify-center items-center">
                <div className={"mx-auto w-full text-center flex flex-col-reverse  md:flex-row "}>

                    <div className={"image-section flex-1 relative w-100"}>
                        <div id={"mockup-base-2"}>
                            <a.img style={springs3} className={"float"} src={"img/app-mockup-7.png"}/>
                        </div>
                    </div>

                    <div ref={ref2} className={"w-full flex-1 text-left flex flex-col align-center justify-center"}>
                        <a.div style={springs3} className={"special-dev"}>
                            COMING SOON
                        </a.div>
                        <a.h2 style={springs3} className={"title text-2xl font-bold mb-3 text-white"}>Streamline
                            utility bills.</a.h2>
                        <a.div style={springs3} className={"xfi-border "}></a.div>
                        <a.p style={springs3} className={"mt-3 text-white"}>An all-in-one bill payment solution.</a.p>
                    </div>


                </div>


            </div>

        </div>
    );
};

export default Experience;

