import React, {useState} from "react";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import "./MultiLanguage.scss";
import {animated as a, useInView, useSpring} from "@react-spring/web";

const MultiLanguage = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const onSubmit = (e) => {
        e.preventDefault();
        console.log("refresh prevented");
    };

    const [ref, isInView] = useInView(
        {
            rootMargin: '-40% 0%',
            once : true
        }
    )

    const springs3 = useSpring(
        isInView ? {
            from: {
                opacity: 0,
                y: 100,
            },
            to: {
                opacity: 1,
                y: 0,
            },
            config: {
                tension:100
            },
        }: {
            from: {
                opacity: 0,
                y: 100,

            }
        })

    return (
        <div ref={ref} className="multi-language section-padding">
            <div className="container relative mx-auto navbar-padding flex flex-col justify-center items-center">
                <div className={"mx-auto w-full text-center flex flex-col-reverse md:flex-row p-5 flex-reverse"}>


                    <a.div style={springs3} className={"w-full flex-1 text-left flex flex-col align-center justify-center md:pr-4"}>
                        <h2 className={"title text-2xl font-bold mb-3 "}>Multiple Languages</h2>
                        <div className={"xfi-border "}></div>
                        <p className={"mt-3 "}>Say
                            goodbye to language barriers
                            by selecting your preferred
                            language.</p>
                        <div className={"flag-container flex flex-row mt-2"}>
                            <img src={"img/australia_flag.png"}/>
                            <img src={"img/bangladesh_flag.png"}/>
                            <img src={"img/india_flag.png"}/>
                            <img src={"img/pakistan_flag.png"}/>
                        </div>
                    </a.div>

                    <a.div style={springs3} className={"image-section flex-1 relative w-100"}>
                            <img src={"img/multi_language.png"}/>
                    </a.div>

                </div>


            </div>

        </div>
    );
};

export default MultiLanguage;

