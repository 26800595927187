
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Home from "./pages/Home/Home";

import "./app.scss"

import Blog from "./pages/Blog/Blog";
import Post from "./pages/Post/Post";
import Navbar from "./components/Navbar/Navbar";



const Layout = () => {


  return (
    <div className="app">
      <div id="control-height"></div>
      <Navbar/>
      <Outlet />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    // errorElement: <Error />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/blog",
        element: <Blog />,
      },
      {
        path: "/post/:postId/:postName",
        element: <Post />,
      },
    ],
  },
]);

function App() {

  return (
    <div>
      <RouterProvider router={router}  />
    </div>
  );
}

export default App;
