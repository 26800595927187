import React, {useState} from "react";

import "./CardList.scss";
import {animated as a, useInView, useSpring} from "@react-spring/web";

const CardList = (props) => {

    const [ref, inView] = useInView(
        {
            rootMargin: '-40% 0%',
            once: true
        }
    )

    const springs = useSpring(
        inView ?
            {
                from: {
                    opacity: 0,
                    y: 100,
                },
                to: {
                    opacity: 1,
                    y: 0,
                },
                config: {
                    tension: 100
                }, delay: 100
            } : {
                from: {
                    opacity: 0,
                    y: 100,

                }, delay: 1000
            });

    const springsCard = useSpring(
        inView ?
            {
                from: {
                    opacity: 0,
                    y: 100,
                },
                to: {
                    opacity: 1,
                    y: 0,
                },
                config: {
                    tension: 100
                }, delay: 100
            } : {
                from: {
                    opacity: 0,
                    y: 100,

                }, delay: 1000
            });

    const onSubmit = (e) => {
        e.preventDefault();
        console.log("refresh prevented");
    };

    return (
        <div ref={ref} className="CardList section-padding">
            <div id="title" className={"relative"}>
                <a.h2 style={springs} className={"text-2xl font-bold"}>Countries We Serve</a.h2>
                <a.div style={springs} className={"xfi-border mx-auto"}></a.div>
            </div>



            <div className="container mt-4 mx-auto grid grid-rows-6 md:grid-rows-3 grid-flow-col">

                {props.data?.map((item) => (
                    <a.div style={springsCard} key={item.id} className={"country_card"}>
                        <div className={"content"}>
                            <img src={item.flag_image.data.attributes.url}/>
                            <p>{item.title}</p>
                        </div>
                    </a.div>
                ))}

            </div>
        </div>
    );
};

export default CardList;
