import React from 'react'

import Tag from '../Tag/Tag';
import {Link} from "react-router-dom";
import "./PostPreview.scss"
import showdown from "showdown";


export default function PostPreview({postArg}) {
    let converter = new showdown.Converter();
    let html = converter.makeHtml(postArg?.attributes?.body); //this description field i want to showdown

    return (

            <div className={`md:w-2/3 sm:w-full`}>
                <div className={'p-2'}>
                    <img src={postArg.attributes?.thumbnail?.data?.attributes.url} className={"w-full"}/>
                    <div className={"pt-3"} dangerouslySetInnerHTML={{__html: html}}/>
                </div>

            </div>

    );
}
