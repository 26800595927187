import React, {useEffect, useState} from "react";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import "./TitleWithImage.scss";
import {animated as a, useInView, useScroll, useTrail} from "@react-spring/web";
import useFetch from "../../hooks/useFetch";

const TitleWithImage = (props) => {

    const { data, loading, error } = useFetch( `/api/header?populate=*` );

    const [currentSlide, setCurrentSlide] = useState(0);

    const items = (data) ? data.attributes.title.split("\n") : [];
    const config = { mass: 2, tension: 100, friction: 30 };
    const [toggle, set] = useState(true);
    const trail = useTrail(items.length, {
        config,
        opacity: toggle ? 1 : 0,
        x: toggle ? 0 : 20,
        height: toggle ? 'auto' : 0,
        from: { opacity: 0, x: 20, height: 0 }
    });


    useEffect(() => {

    }, [])
    useEffect(() => {
        if(data){
            props.incrementReady();
            console.log("api called");
        }
    },[data])

    const [ref, springs] = useInView(
        () => ({
            from: {
                opacity: 0,
                y: 100,
            },
            to: {
                opacity: 1,
                y: 0,
            },
        }),
        {
            rootMargin: '-40% 0%',
            once : true
        }
    )

    return (
        <div ref={ref} id={"about"} className="title_image section-padding">
            <div className="container mx-auto flex flex-col justify-center items-center">
                <div className={"mx-auto text-center relative"}>
                    <a.h2 className={"text-2xl font-bold mb-3"}  style={springs}>Send. Exchange. Manage. <p className={"blue-text text-sm"}>Do more with the xfi intuitive mobile app.</p></a.h2>


                    <div className={"image-section relative w-100"}>
                        <div id={"back-circle"}>
                            <img src={"img/backdrop_circle.svg"}/>
                        </div>
                        <img className={"relative float"} src={"img/floating_xfi_card.png"}/>
                    </div>

                </div>


            </div>
        </div>
    );
};

export default TitleWithImage;
