import React, {useCallback, useEffect, useState} from 'react'
import "./Home.scss"
import Header from "../../components/header/Header";
import TitleWithImage from "../../components/TitleWithImage/TitleWithImage";
import MapTransfer from "../../components/MapTransfer/MapTransfer";
import CardList from "../../components/CardList/CardList";
import Rates from "../../components/Rates/Rates";
import RegisterSnap from "../../components/RegisterSnap/RegisterSnap";
import HorizontalImageText from "../../components/HorizontalImageText/HorizontalImageText";
import Experience from "../../components/Experience/Experience";
import LoyaltyProgram from "../../components/LoyaltyProgram/LoyaltyProgram";
import CustomerService from "../../components/CustomerService/CustomerService";
import MultiLanguage from "../../components/MultiLanguage/MultiLanguage";
import DownloadApp from "../../components/DownloadApp/DownloadApp";
import Footer from "../../components/Footer/Footer";
import Testimonials from "../../components/Testimonials/Testimonials";
import Faq from "../../components/Faq/Faq";
import Loading from "../../components/Loading/Loading";

import {makeRequest} from "../../makeRequest";

const Home = (props) => {



    const [readyNumber, setReadyNumber] = useState(0);
    const [countryServed, setCountryServed] = useState([]);
    const [rates, setRates] = useState([]);
    const [faq, setFAQ] = useState([]);
    const [testimonials, setTestimonials] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const countryListRequest = await makeRequest.get("api/country?populate=*&populate=countries.flag_image");
            setCountryServed(countryListRequest.data.data.attributes.countries)

            const ratesRequest = await makeRequest.get("api/rate?populate=*");
            setRates(ratesRequest.data.data.attributes.rates)

            const faqRequest = await makeRequest.get("api/faq?populate=*");
            setFAQ(faqRequest.data.data.attributes.faq)

            const testimonialsRequest = await makeRequest.get("api/testimonials?populate=*");
            setTestimonials(testimonialsRequest.data.data)

            const script = document.createElement("script")
            script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}`
            document.body.appendChild(script)
            // setCountryServed(respGlobal.data);
        };

        fetchData();
    }, []);
    // useEffect(() => {
    //     if(data != null) {
    //         setCountryServed(data.attributes.countries)
    //     }
    // },[data])

    const incrementReady = () => {
        // Update the name in the component's state
        setReadyNumber(readyNumber + 1)
    }

        return (
            <div className='home'>
                
                <Loading/>
                <Header incrementReady={incrementReady}/>
                <div className={"extra-spacing"}></div>
                {/*<Subscription/>*/}
                <TitleWithImage incrementReady={incrementReady}/>
                <MapTransfer data={rates}/>
                <CardList data={countryServed} />

                <Rates data={rates} />
                <RegisterSnap />
                <HorizontalImageText />
                <Experience />
                <LoyaltyProgram />
                <CustomerService />
                <MultiLanguage />
                <Testimonials data={testimonials}/>
                <Faq data={faq} />
                <DownloadApp />
                <Footer />

            </div>
        )

}

export default Home