import React, {useState} from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import {Link} from "react-router-dom";
import "./Navbar.scss"
import {useSelector} from "react-redux";
import $ from 'jquery';
import useFetch from "../../hooks/useFetch";
import Card from "../Card/Card";
const Navbar = () => {
    const [open, setOpen] = useState(false)
    const {hash, pathname, search} = window.location;
    const { data, loading, error } = useFetch( `/api/navbar?populate=*` );
    let hashName = hash.substring(1);

    React.useEffect(() => {
        var navbar = document.getElementById("navbar");
        var navbarJquery = $("#navbar");
        const toggleClass = "is-sticky";
        let lastScroll = 0;
        console.log("scroll to : " + hashName);
        window.addEventListener("load",()=>{
            document.getElementById(hashName)?.scrollIntoView();
        })
        window.addEventListener("scroll", () => {
            const currentScroll = window.scrollY;

            if(currentScroll > lastScroll && currentScroll > 150){
                lastScroll = currentScroll;

                navbarJquery.fadeOut();

            }else{
                navbarJquery.slideDown();
                lastScroll = currentScroll;
            }

            if (currentScroll > 150) {

                navbar.classList.add(toggleClass);
            } else {
                navbar.classList.remove(toggleClass);
                navbarJquery.show();
            }
        });
    });



    return (
        <div className="navbar" id={"navbar"}>
            <div className="wrapper">

                <div className="left">
                    <div className="item relative">
                        <Link to={"./"} onClick={() => window.location.href="./"} style={{height:"100%", width:"100%",position:"absolute",top:"0px",left:"0px"}}></Link>
                        <img src="img/xfi logo.svg" alt=""/>
                    </div>
                </div>


                <div className="center hidden md:block">
                    <ul className="text-xsm">
                        {(data) ? (data.attributes.navlinks?.map((item) => (<li key={item.title}><a href={item.section_id}>{item.title}</a></li>))) : <li></li>}

                        {/*{error*/}
                        {/*    ? "Something went wrong!"*/}
                        {/*    : loading*/}
                        {/*        ? "loading"*/}
                        {/*        : data.attributes.navlinks?.map((item) => <li><a href={"#" + item.section_id}>{item.title}</a></li>)}*/}

                    </ul>
                </div>


                <div className="right">
                    <a href={"#get-xfi"}>
                        <button className="xfi-button rounded-full text-xsm">
                            Get xfi
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
