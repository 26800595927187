import React, {useEffect, useState} from 'react'

import "./Post.scss"
import DownloadApp from "../../components/DownloadApp/DownloadApp";
import Footer from "../../components/Footer/Footer";
import PostPreview from "../../components/PostPreview/PostPreview";
import {Link, useParams} from "react-router-dom";
import {makeRequest} from "../../makeRequest";
import Loading from "../../components/Loading/Loading";

const Post = (props) => {
    const [postDetails, setPostDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const params = useParams()
    let postId = params.postId
    if (postId == null) {
        window.href.location("./");
    }

    if(postDetails != null) {
        console.log("Post : " + postDetails.attributes);
    }
    useEffect(() => {
        const fetchData = async () => {
            const blogsRequest = await makeRequest.get(`api/blogs/${postId}?populate=*`);
            setPostDetails(blogsRequest.data.data);
        };
        const script = document.createElement("script")
        script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}`
        document.body.appendChild(script)
        fetchData();
    }, []);

    return (
        (postDetails?.attributes != null) ?
            <div className='post'>
                <Loading loading={loading}/>
                <div className="post-header"
                     style={{backgroundImage: `url(${postDetails.attributes.thumbnail.data?.attributes.url})`}}>
                    <div id="title" className={"relative"}>
                        <h2 className={"text-2xl font-bold uppercase"}>{postDetails.attributes.title}</h2>
                        <p className={" mt-2"}><Link className={'font-bold'}
                                                     to={`/blog`}>Blog</Link> / {postDetails.attributes.title}</p>
                    </div>
                </div>
                <div
                    className="container relative mx-auto navbar-padding flex flex-wrap justify-center items-center">
                    <PostPreview postArg={postDetails}/>

                </div>
                <DownloadApp type="trending"/>
                <Footer type="trending"/>
            </div>
            : null
)

}

export default Post