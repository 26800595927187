import React, {useState} from "react";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import "./DownloadApp.scss";

const DownloadApp = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const onSubmit = (e) => {
        e.preventDefault();
        console.log("refresh prevented");
    };

    return (
        <div id={"get-xfi"} className="download-app section-padding">
            <div className="container relative mx-auto flex flex-col justify-center items-center">
                <div
                    className={"mx-auto white-bg text-center flex flex-col-reverse md:flex-row p-10 flex-reverse rounded-3xl"}>


                    <div className={"w-full flex-1 text-left flex flex-col align-center justify-center sm:text-center"}>
                        <h2 className={"title text-2xl font-bold mb-3 "}>Get xfi</h2>
                        <p className={"mt-3 "}>Track all your accounts in one place and never
                            miss another bill</p>

                    </div>

                    <div className={"image-section flex-1 relative w-100"}>
                        <div className={"download-button flex flex-col mt-2"}>
                            <a target={"_blank"} href={process.env.REACT_APP_IOS_URL}><img src={"img/appstore_button.png"}/></a>
                            <a target={"_blank"} href={process.env.REACT_APP_ANDROID_URL}><img src={"img/googleplay_download.png"}/></a>
                        </div>
                    </div>

                </div>


            </div>

        </div>
    );
};

export default DownloadApp;

