import React, {useState} from "react";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import "./RegisterSnap.scss";
import {useEffect} from 'react';
import {animated as a, useInView, useSpring} from "@react-spring/web";


const RegisterSnap = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const onSubmit = (e) => {
        e.preventDefault();
        console.log("refresh prevented");
    };

    useEffect(() => {

// stop animation after 3 seconds


    }, []);


    const [ref, springs] = useInView(
        () => ({
            from: {
                opacity: 0,
                y: 100,
            },
            to: {
                opacity: 1,
                y: 0,
            },
        }),
        {
            rootMargin: '-40% 0%',
            once : true
        }
    )
    const [ref2, isInView] = useInView(
        {
            rootMargin: '-40% 0%',
            amount: 0,
        }
    )
    const springs2 = useSpring(
        isInView ? {
        from: {
            opacity: 0,
            y: 100,

        },
        to: {
            opacity: 1,
            y: 0,
        },
        config: {
            tension:100
        },    delay:100
    }: {
            from: {
                opacity: 0,
                y: 100,

            },    delay:1000
        })

    return (
        <div ref={ref} id={"about-xfi"} className="register-snap section-padding text-center md:h-screen">
            <div className={"background-offset"}></div>

            <div ref={ref2} className={"container"}>
            <div className={"flex relative md:flex-row flex-col"}>
                <a.div style={springs} className={"flex-1 content-section"} >
                    <h2 className={"text-2xl font-bold mb-3 title text-white"}>Register In A Snap</h2>
                    <div className={"xfi-border"}></div>
                    <p className={"text-white mt-4"}>Instant verification process,
                        enabled by facial recognition, and without the
                        need to visit a physical branch.</p>

                    <div className={"flex mt-4"}>

                        <div className={"feature"}>
                            <img src={"img/uae_pass.png"}/>
                            <p className={"text-xsm text-white"}>UAE Pass</p>
                        </div>
                        <div className={"feature"}>
                            <img src={"img/ai_engine.png"}/>
                            <p className={"text-xsm text-white"}>AI Engine</p>
                        </div>
                    </div>
                </a.div>
                <a.div className={"flex-1 mockup-section"}>

                    <a.img  style={springs} src={"img/mockup-app-3.png"}/>
                    <a.img style={springs2} src={"img/mockup-app-2.png"}/>
                </a.div>
            </div>
            </div>
        </div>
    );
};

export default RegisterSnap;
