import React, {useState} from "react";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import "./CustomerService.scss";
import {useEffect} from 'react';
import {animated as a, useInView, useSpring} from "@react-spring/web";


const CustomerService = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const onSubmit = (e) => {
        e.preventDefault();
        console.log("refresh prevented");
    };





    const [ref2, isInView2] = useInView(
        {
            rootMargin: '-40% 0%',
            amount: 0,
        }
    )

    const springChat1 = useSpring(
        isInView2 ? {
            from: {
                scale:0
            },
            to: {
                scale:1
            },
            config: {
                tension:100
            },    delay:200
        }: {
            from: {
                scale:0
            },    delay:200
        })
    const springs3 = useSpring(
        isInView2 ? {
            from: {
                opacity: 0,
                y: 100,
            },
            to: {
                opacity: 1,
                y: 0,
            },
            config: {
                tension:100
            },
        }: {
            from: {
                opacity: 0,
                y: 100,

            }
        })
    const springChat2 = useSpring(
        isInView2 ? {
            from: {
                scale:0
            },
            to: {
                scale:1
            },
            config: {
                tension:100
            },    delay:800
        }: {
            from: {
                scale:0

            },    delay:3000
        })



    useEffect(() => {

// stop animation after 3 seconds


    }, []);

    return (
        <div ref={ref2} className="customer-service section-padding text-center ">
            <div className={"background-offset"}></div>

            <div className={"container"}>
                <div  className={"flex relative md:flex-row flex-col"}>
                    <div className={"flex-1 content-section"}>
                        <a.div style={springChat1} className={"transfer-card"} >
                            <div className={"transfer-card-wrapper"}>
                                <div className={"img-section"}>
                                    <img className={""} src={"img/avatar.png"}/>
                                </div>
                                <div className={"content-section"}>
                                    <div>
                                        <p className={"text-sm purple-bg"}>Hi Amir! How can I help you today?</p>
                                    </div>
                                </div>
                            </div>

                        </a.div>
                        <a.div style={springChat2} className={"transfer-card self-end mt-3"} >
                            <div className={"transfer-card-wrapper"}>
                                <div className={"img-section"}>
                                    <img className={"rounded-full"} src={"img/amir.png"}/>
                                </div>
                                <div className={"content-section"}>
                                    <div>
                                        <p className={"text-sm yellow-bg"}>I want to buy INR</p>
                                    </div>
                                </div>
                            </div>

                        </a.div>
                    </div>


                    <div className={"flex-1 text-left md:pl-3"}>
                        <a.h2 style={springs3} className={"title text-2xl font-bold mb-3 text-white my-5 md:my-0 pb-4"} >Exceptional{"\n"}
                            Customer{"\n"}
                            Support</a.h2>
                        <a.div style={springs3} className={"xfi-border "}></a.div>
                        <a.p style={springs3} className={"mt-3 text-white"}>Access live chat support and a 24/7
                            hotline for immediate assistance.</a.p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomerService;
