import React, {useState} from "react";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import "./LoyaltyProgram.scss";
import {useInView, useSpring} from "@react-spring/web";
import {animated as a} from "@react-spring/web";

const LoyaltyProgram = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const onSubmit = (e) => {
        e.preventDefault();
        console.log("refresh prevented");
    };

    const [ref, isInView] = useInView(
        {
            rootMargin: '-40% 0%',
            amount: 0,
        }
    )
    const [ref2, isInView2] = useInView(
        {
            rootMargin: '-40% 0%',
            amount: 0,
        }
    )
    const springs = useSpring(
        isInView ? {
            from: {
                opacity: 0,
                y: 100,
            },
            to: {
                opacity: 1,
                y: 0,
            },
            config: {
                tension:100
            },    delay:100
        }: {
            from: {
                opacity: 0,
                y: 100,

            },    delay:1000
        })
    const springs2 = useSpring(
        isInView2 ? {
            from: {
                opacity: 0,
                y: 100,
            },
            to: {
                opacity: 1,
                y: 0,
            },
            config: {
                tension:100
            },    delay:100
        }: {
            from: {
                opacity: 0,
                y: 100,

            },    delay:1000
        })


    return (
        <div id="features" ref={ref} className="loyalty-program section-padding-sm">
            <div className={"background-offset"}></div>
            <div className="container relative mx-auto navbar-padding flex flex-col justify-center items-center">
                <div className={"mx-auto w-full text-center flex flex-col md:flex-row p-5"}>

                    <div className={"image-section flex-1 relative w-100 "}>
                        <a.img style={springs} src={"img/loyalty_program.png"}></a.img>
                    </div>

                    <div  className={"w-full flex-1 text-left flex flex-col align-center justify-center  md:pl-4"}>
                        <a.h2 style={springs} className={"title text-2xl font-bold mb-3 "}>Loyalty{"\n\n"}
                            Programs</a.h2>
                        <a.div style={springs} className={"xfi-border "}></a.div>
                        <a.p style={springs} className={"mt-3"}>Gain a host of exciting offers and rewards.</a.p>
                    </div>


                </div>


            </div>

            <div ref={ref2} className="container relative mx-auto navbar-padding flex flex-col justify-center items-center">
                <div className={"mx-auto w-full text-center flex flex-col-reverse md:flex-row p-5 flex-reverse"}>


                    <div className={"w-full flex-1 text-left flex flex-col align-center justify-center  md:pr-4"}>
                        <a.h2 style={springs2} className={"title text-2xl font-bold mb-3 "}>Payment Methods</a.h2>
                        <a.div style={springs2} className={"xfi-border "}></a.div>
                        <a.p style={springs2} className={"mt-3 "}>Pay by bank transfer, debit card (Visa/Mastercard), or salary card; receive by bank account, cash pickup, or mobile wallet.</a.p>
                    </div>

                    <div className={"image-section flex-1 relative w-100"}>
                            <a.img style={springs2} src={"img/safe and secure.png"}/>
                    </div>

                </div>


            </div>

        </div>
    );
};

export default LoyaltyProgram;

