import React, {useState} from "react";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import "./Faq.scss";
import {useEffect} from 'react';
import {animated as a, useInView, useSpring} from "@react-spring/web";
import {isArray} from "jquery";

const Faq = (props) => {
    let faqList = props.data
    if(!Array.isArray(faqList)){
        faqList = [];
    }
    const [ref, inView] = useInView(
        {
            rootMargin: '-40% 0%',
            once: true
        }
    )

    const springs = useSpring(
        inView ?
            {
                from: {
                    opacity: 0,
                    y: 100,
                },
                to: {
                    opacity: 1,
                    y: 0,
                },
                config: {
                    tension: 100
                }, delay: 100
            } : {
                from: {
                    opacity: 0,
                    y: 100,

                }, delay: 1000
            });

    const springsCard = useSpring(
        inView ?
            {
                from: {
                    opacity: 0,
                    y: 100,
                },
                to: {
                    opacity: 1,
                    y: 0,
                },
                config: {
                    tension: 100
                }, delay: 100
            } : {
                from: {
                    opacity: 0,
                    y: 100,

                }, delay: 1000
            });

    const onSubmit = (e) => {
        e.preventDefault();
        console.log("refresh prevented");
    };

    const [open, setOpen] = React.useState(1);

    const handleOpen = (value) => {
        console.log("======== before")
        console.log(value);
        console.log(open);
        setOpen(open === value ? 0 : value);
        console.log("======== after")
        console.log(value);
        console.log(open);
    }


    return (
        <a.div id={"faq"} ref={ref} style={springs} className="faq section-padding">
            <div id="title" className={"relative"}>
                <h2  className={"text-2xl font-bold"}>Frequently asked questions</h2>

            </div>


            <div className="flex justify-center items-start my-2">
                <div className="w-full sm:w-10/12 md:w-1/2 my-1">
                    <ul className="flex flex-col">
                        {faqList.map((faq, index) => (
                            <li key={index} className={`bg-white my-2 shadow-lg ${(index+1 == open) ? "active" : ""}`} onClick={() => handleOpen(index+1)}>
                                <h2 className="flex flex-row justify-between items-center font-semibold p-3 cursor-pointer"
                                >
                                    <span>{faq.Title}</span>
                                    <svg
                                        className={`fill-current blue-text h-6 w-6 transform transition-transform duration-500 ${(index+1 == open) ? "rotate-180" : ""}`}
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10"></path>
                                    </svg>
                                </h2>
                                <div  className={`border-l-2 border-left-yellow overflow-hidden transition-all duration-500 text-left ${(index+1 !== open) ? "max-h-0" : "max-h-80"}`}>
                                    <p className="p-3 text-gray-900">
                                        {faq.Description}
                                    </p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </a.div>
    );
};

export default Faq;
