import React, {useState} from "react";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import "./MapTransfer.scss";
import {useEffect} from 'react';
import {animated as a, useInView, useSpring} from "@react-spring/web";


const MapTransfer = (props) => {

    let rates = props.data
    const [inrRate, setInrRate] = useState(0);


    const onSubmit = (e) => {
        e.preventDefault();
        console.log("refresh prevented");
    };

    const [ref, springs] = useInView(
        () => ({
            from: {
                opacity: 0,
                y: 100,
            },
            to: {
                opacity: 1,
                y: 0,
            },
        }),
        {
            rootMargin: '-40% 0%',
            once: true
        }
    )

    const [ref2, isInView] = useInView(
        {
            rootMargin: '-40% 0%',
            amount: 0,
        }
    )

    const springsSent = useSpring(
        isInView ? {
            from: {
                scale: 0
            },
            to: {
                scale: 1
            },
            config: {
                tension: 100
            }, delay: 200
        } : {
            from: {
                scale: 0
            }, delay: 200
        })
    const springsVisible = useSpring(
        isInView ? {
            from: {
                opacity: 0,
            },
            to: {
                opacity: 1,
            },
            config: {
                tension: 80
            }, delay: 400
        } : {
            from: {
                opacity: 0,
            }, delay: 400
        })
    const springsReceive = useSpring(
        isInView ? {
            from: {
                scale: 0
            },
            to: {
                scale: 1
            },
            config: {
                tension: 100
            }, delay: 800
        } : {
            from: {
                scale: 0

            }, delay: 3000
        })


    useEffect(() => {
        setInrRate(parseFloat(1/(rates[0]?.rates?.INR))?.toFixed(4));
    },[rates])

    useEffect(() => {

        const canvas = document.getElementById("map-canvas");
        const ctx = canvas.getContext("2d");

        window.addEventListener('resize', resizeCanvas, false);

        function resizeCanvas() {
            canvas.width = document.getElementById("map-exchange").offsetWidth;
            canvas.height = document.getElementById("map-exchange").offsetHeight;

            /**
             * Your drawings need to be inside this function otherwise they will be reset when
             * you resize the browser window and the canvas goes will be cleared.
             */
            drawStuff();
        }

        resizeCanvas();

        window.addEventListener("load", (event) => {
            resizeCanvas();
        });

        function drawStuff() {
            // Define canvas and context



            // animate the line
            let animation;
            let offset = 0;

            // Define the points as {x, y}
            let start = {x: canvas.width * 0.22, y: canvas.height * 0.38};
            let cp1 = {x: canvas.width * 0.4, y: canvas.height * 0.5};
            let cp2 = {x: canvas.width * 0.4, y: canvas.height * 0.8};
            let end = {x: canvas.width * 0.61, y: canvas.height * 0.55};



            let radius_size = 12;
            if (canvas.width < 550) {
                 cp1 = {x: canvas.width * 0.3, y: canvas.height * 0.4};
                 cp2 = {x: canvas.width * 0.4, y: canvas.height * 0.6};
                radius_size = 4;
            }
            const animate = () => {
                animation = requestAnimationFrame(animate);
                ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

                offset += 1;
                ctx.lineDashOffset = offset;

                // Cubic Bézier curve
                ctx.strokeStyle = "#FFFFFF";
                ctx.lineWidth = 2;
                ctx.beginPath();
                ctx.setLineDash([15, 5]);
                ctx.moveTo(start.x, start.y);
                ctx.bezierCurveTo(cp1.x, cp1.y, cp2.x, cp2.y, end.x, end.y);
                ctx.stroke();
                // Start and end points
                ctx.fillStyle = "#FFDD31";
                ctx.beginPath();
                ctx.arc(start.x, start.y, radius_size, 0, 2 * Math.PI); // Start point
                ctx.arc(end.x, end.y, radius_size, 0, 2 * Math.PI); // End point
                ctx.fill();

            };

            animate();

// stop animation after 3 seconds


        }
    }, []);

    return (
        <div ref={ref} className="map-transfer section-padding-sm text-center ">

            <div id="title" className={"relative"}>
                <div id="title-body">
                    <a.h2 style={springs} className={"text-2xl font-bold text-white"}>Worldwide Transfers Made Easy
                    </a.h2>
                </div>
            </div>

            <div ref={ref2} id={"map-container"} className={"map-container relative w-full"}>
                <div style={{position:`relative`}}>
                    <img id={"map-exchange"} src={"img/map_exchange.svg"}/>
                    <div className={"transfer-card"} id={"exchange_from"}>
                        <a.div style={springsSent} className={"transfer-card-wrapper"}>
                            <div className={"img-section"}>
                                <img className={"rounded-full"} src={"img/yasmine.png"}/>
                            </div>
                            <div className={"content-section"}>
                                <div className={"md:m-3"}>
                                    <p className={"text-sm"}>Sent from Yasmine</p>
                                    <div className={"details pl-2 pr-3"}>
                                        <div>
                                            <p className={"text-xxxsm md:text-xxxsm"}>{`1 AED = ${inrRate} INR`}</p>
                                        </div>
                                        <div>
                                            <p className={"total-amount text-xxxsm md:text-sm"}>AED 5000.00</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a.div>

                    </div>
                    <div className={"transfer-card"} id={"exchange_to"}>
                        <a.div style={springsReceive} className={"transfer-card-wrapper"}>
                            <div className={"img-section"}>
                                <img className={"rounded-full"} src={"img/amir.png"}/>
                            </div>
                            <div className={"content-section"}>
                                <div className={"md:m-3"}>
                                    <p className={"text-sm"}>Received by Amir</p>
                                    <div className={"details pl-2 pr-3"}>
                                        <div>
                                            <p className={"text-xxxsm md:text-xxxsm"}>1 AED = {inrRate} INR</p>
                                        </div>
                                        <div>
                                            <p className={"total-amount text-xxxsm md:text-sm"}>AED 5000.00</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a.div>

                    </div>

                    <a.canvas style={springsVisible} id="map-canvas" width={"100%"} height={"100%"}></a.canvas>
                </div>
            </div>

        </div>
    );
};

export default MapTransfer;
