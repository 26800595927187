import React from 'react'
import {Link} from "react-router-dom";
import "./PostCard.scss"

export default function PostCard({post}) {
    return (
        <div className='post-container transition-transform duration-200 ease-out hover:scale-[1.02]'>

            <Link to={`/post/${post.id}/${post.slug}`}>
                <img
                    className=' h-auto w-full bg-no-repeat object-cover object-center '
                    src={`${post.attributes.thumbnail.data.attributes.url}?w=1400&h=340&auto=format`}
                    width={1400}
                    height={340}
                    priority
                    alt={post.attributes.title}
                />
            </Link>
            <div className={`p-5`}>
                <h2 className='pb-1 blue-text text-sm font-semibold'>
                    <Link to={`/post/${post.id}/${post.attributes.slug}`}>{post.attributes.title}</Link>
                </h2>
                <div className='py-2 text-sm' dangerouslySetInnerHTML={{__html: post.attributes.teaser ?? ''}}/>
                <div className='flex justify-center items-center'>
                    <Link to={`/post/${post.id}/${post.attributes.slug}`}>
                        <div className='flex items-center space-x-2 text-sm blue-text text-center read-more-btn'>
                            <span>Read more</span>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}
