import React, {useState} from "react";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import "./HorizontalImageText.scss";
import {animated as a, useInView, useScroll, useTrail} from "@react-spring/web";
const HorizontalImageText = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const onSubmit = (e) => {
        e.preventDefault();
        console.log("refresh prevented");
    };


    const [ref, springs] = useInView(
        () => ({
            from: {
                opacity: 0,
                y: 100,
            },
            to: {
                opacity: 1,
                y: 0,
            },
        }),
        {
            rootMargin: '-40% 0%',
            once : true
        }
    )

    return (
        <div  className="horizontal-image-text section-padding">
            <div className="container mx-auto navbar-padding flex flex-col  justify-center items-center">
                <div className={"mx-auto w-full text-center flex flex-col md:flex-row "}>

                    <div className={"image-section flex-1 relative w-100"}>
                        <div id={"back-circle"}>
                            <img src={"img/backdrop_circle.svg"}/>
                        </div>
                        <img className={"relative app-mockup float"} src={"img/mockup-app-4.png"}/>
                    </div>

                    <a.div ref={ref} className={"w-full flex-1 text-left flex flex-col align-center justify-center p-4 p-md-0"}>
                    <a.h2 className={"title text-2xl font-bold mb-3"}  style={springs}>Connect To Your
                        Bank Securely.</a.h2>
                        <a.div style={springs} className={"xfi-border "}></a.div>
                    <a.p className={"blue-text mt-3"} style={springs} >Link your Card or Bank Account to
                        the xfi app with confidence.</a.p>
                    </a.div>

                </div>


            </div>
        </div>
    );
};

export default HorizontalImageText;
