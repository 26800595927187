import React, {useEffect, useState} from 'react'

import "./Blog.scss"

import DownloadApp from "../../components/DownloadApp/DownloadApp";
import Footer from "../../components/Footer/Footer";

import BlogList from "../../components/BlogList/BlogList";
import {makeRequest} from "../../makeRequest";
import Loading from "../../components/Loading/Loading";

const Blog = (props) => {

    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const blogsRequest = await makeRequest.get("api/blogs?populate=*&sort[0]=id:desc");
            setBlogs(blogsRequest.data.data);
            setLoading(false);
        };
        const script = document.createElement("script")
        script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}`
        document.body.appendChild(script)
        fetchData();
    }, []);
    return (
        <div className='home'>
            <Loading loading={loading}/>
            {/*<Subscription/>*/}
            <div className="blog-header">
                <div id="title" className={"relative"}>
                    <h2 className={"text-2xl font-bold"}>Blog</h2>
                </div>
            </div>

            <BlogList posts={blogs}/>

            <DownloadApp type="trending"/>
            <Footer type="trending"/>

        </div>
    )

}

export default Blog