import React, {useRef, useState} from "react";
import "./Footer.scss";
import ReCAPTCHA from "react-google-recaptcha"
import {makeRequest} from "../../makeRequest";
import {capitalizeFirstLetter} from "../../script/Utils";
const Footer = () => {
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [toastVisible, setToastVisible] = useState(false);
    const [toastMessage, setToastMessage] = useState("");

    const onSubmit = async (e) => {
        e.preventDefault();
        const data = Array.from(e.target.elements)
            .filter((input) => input.name)
            .reduce((obj, input) => Object.assign(obj, { [input.name]: input.value }), {});

        window.grecaptcha.ready(_ => {
            window.grecaptcha
                .execute(process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY, { action: "homepage" })
                .then(async token => {
                    if(data["email"] == ""){
                        data["email"] = null;
                    }
                    data["gRecaptchaToken"] = token;
                    let request_data = {};
                    request_data["data"] = data;
                    setFormSubmitting(true);
                    const submitFormRequest = await makeRequest.post("api/subscribers",request_data).then(result => {
                        e.target.reset();
                        setFormSubmitting(false);
                        setToastMessage(capitalizeFirstLetter("Your request was submitted successfully"));
                    }).catch(reason => {
                        setFormSubmitting(false);
                        console.log(reason);
                        if(reason.response?.data?.error.hasOwnProperty("message")){
                            setToastMessage(capitalizeFirstLetter(reason.response.data.error.message));
                        }else{
                            setToastMessage("Something went wrong submitting your request. Please try again later.");
                        }
                    });
                    setToastVisible(true);
                    setTimeout(
                        function() {
                            setToastVisible(false);
                        }
                            .bind(this),
                        3500
                    );
                })
        })


        console.log("refresh prevented");
    };

    return (
        <div className="footer section-padding">

            <div id="toast-message"
                 className={`fixed toast-message flex items-center w-full max-w-xs p-4 space-x-4 text-gray-500 bg-white divide-x rtl:divide-x-reverse divide-gray-200 rounded-lg shadow right-5 bottom-5 dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800 ` + (toastVisible?'fadeIn':'fadeOut')}
                 role="alert">
                <div className="text-sm font-normal">{toastMessage}</div>
            </div>

            <div className="container relative mx-auto flex flex-col justify-center items-center">
                <div
                    className={"mx-auto text-center flex flex-col md:flex-col "}>


                    <div className={"w-full flex-1 text-left flex flex-col align-center justify-center"}>
                        <h2 className={"title text-2xl font-bold mb-3 "}>Money Moves. Trust Stays.</h2>
                        <p className={"mx-auto"}>Get early access to the xfi app today!</p>
                    </div>
                    <div className={""}>
                        <form className={"flex flex-col md:flex-row gap-1 text-black mt-4"} onSubmit={onSubmit}>
                            <div className={"flex-1"}><input type={"text"} className={"text-m md:text-xxsm w-full"} name={"mobile_phone"}
                                                             placeholder={"Mobile Number"} required={true}/></div>
                            <div className={"flex-1"}><input type={"text"} className={"text-m md:text-xxsm w-full"} name={"email"}
                                                             placeholder={"Email Address (optional)"}/></div>
                            <div className={"flex-1"}>
                                <button className={"w-full xfi-button-rounded  text-xsm"} type="submit">
                                    <div className={"flex items-center justify-center"}>
                                    <svg width="20" height="20" fill="currentColor" className={"transition-all mr-2 animate-spin " + (formSubmitting ? "block" : "hidden") }
                                         viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z">
                                        </path>
                                    </svg>
                                        <p className={"transition-all " + (formSubmitting ? "hidden" : "block")}>Sign Up</p>
                                    </div>
                                </button>
                            </div>


                        </form>
                    </div>

                </div>


            </div>

        </div>
    );
};

export default Footer;
