import React, {useEffect, useRef, useState} from "react";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import "./Rates.scss";
import {animated as a, useInView, useSpring} from "@react-spring/web";
import CanvasJSReact from '@canvasjs/react-charts';
import $ from "jquery";
import {useMediaQuery} from "@mui/material";
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var dataPoints =[];
const Rates = (props) => {

    let rates = props.data
    var dps = []
    const [render, setRender] = useState(false);
    const onSubmit = (e) => {
        e.preventDefault();
        console.log("refresh prevented");
    };

    const isMobile = useMediaQuery('(max-width:600px)');

    var chart = useRef(null);

    const [ref, isInView] = useInView(
        {
            rootMargin: '-40% 0%',
            once : true
        }
    )

    const springs = useSpring(
        isInView ? {
            from: {
                opacity: 0,
                y: 100,
            },
            to: {
                opacity: 1,
                y: 0,
            },
            config: {
                tension:100
            },

        }: {
            from: {
                opacity: 0,
                y: 100,

            }
        })

    const springs1 = useSpring(
        isInView ? {
            from: {
                opacity: 0,
                y: 100,
            },
            to: {
                opacity: 1,
                y: 0,
            },
            config: {
                tension:100
            },
            delay: isMobile ? 700 : 100
        }: {
            from: {
                opacity: 0,
                y: 100,

            }
        })

    const springs2 = useSpring(
        isInView ? {
            from: {
                opacity: 0,
                y: 100,
            },
            to: {
                opacity: 1,
                y: 0,
            },
            config: {
                tension:100
            },
            delay:  isMobile ? 100 : 400
        }: {
            from: {
                opacity: 0,
                y: 100,

            }
        })

    const springs3 = useSpring(
        isInView ? {
            from: {
                opacity: 0,
                y: 100,
            },
            to: {
                opacity: 1,
                y: 0,
            },
            config: {
                tension:100
            },
            delay: isMobile ? 400 : 700
        }: {
            from: {
                opacity: 0,
                y: 100,

            }
        })
    const springsZoom = useSpring(
        isInView ? {
            from: {
                scale: 0,
                y: 0,
            },
            to: {
                scale: 1,
                y: 0,
            },
            config: {
                tension:100
            },
        }: {
            from: {
                scale: 0,
                y: 100,

            }
        });

    var options = {
        animationEnabled: true,
        axisY: {
            gridThickness: 0,
            lineThickness: 0,
            tickThickness: 0,
            valueFormatString:" ",
            stripLines: [
                {
                    value: 0,
                    thickness: 0,
                    lineThickness: 0,
                    tickThickness: 0
                }
            ]
        },
        axisX: {
            gridThickness: 0,
            lineThickness: 0,
            tickThickness: 0,
            valueFormatString:" ",
            stripLines: [
                {
                    value: 0,
                    thickness: 0,
                    lineThickness: 0,
                    gridThickness: 0,
                    tickLength: 0
                }
            ]
        },
        data: [{

            xValueFormatString: "MM-YYYY",
            type: "spline",
            dataPoints: dataPoints
        }]
    }

    useEffect(() => {
        console.log(rates);
        for (var i = 0; i < rates.length; i++) {
            var date = rates[i].dateOfLastUpdate;
            var year = date.slice(0,4);
            var month = date.slice(5,7);
            console.log("date : " + year + " " + month);
            dataPoints.push({x: new Date(year, month), y: rates[i].rates.PKR});
        }
        chart?.current?.render();
        setRender(true)
    },[rates])
    return (
        <div ref={ref} id={"rates"} className="maximize-saving section-padding">
            <div className={"flex container flex-wrap gap-4 mx-auto"}>
                <div className={"flex-1 flex justify-center"}>
                    <div className={"flex flex-col card-wrapper"}>
                        <a.div style={springs1} className={"flex flex-1 relative items-center currency-card w-inherit"}>
                            <div className={"flex-none country-flag pr-4"}>
                                <img src={"img/india_flag.png"}/>
                            </div>
                            <div className={"flex-1"}>
                                <p className={"text-sm mb-2"}>INR</p>

                                <p className={"text-sm mb-0 mt-0"}>1 AED = {parseFloat(1/(rates[0]?.rates?.INR))?.toFixed(4)} INR</p>
                                <p className={"text-sm mb-0 mt-0"}>1 INR = {rates[0]?.rates?.INR} AED</p>
                            </div>
                            <div><p className={"px-3 py-1 text-sm cardview"}>{parseFloat(1/(rates[0]?.rates?.INR))?.toFixed(2)}</p></div>
                        </a.div>

                        <a.div style={springs2} className={"flex flex-1 flex-col relative currency-card"}>
                            <div className={"flex flex-1 relative items-center"}>
                                <div className={"flex-none  country-flag-sm pr-3"}>
                                    <img src={"img/pakistan_flag.png"}/>
                                </div>
                                <div>
                                    <p className={"text-sm "}>PKR</p>
                                </div>
                                <div className={"flex-1 flex justify-end"}>
                                    <p className={"px-3 py-1 text-sm cardview"}>{parseFloat((rates[0]?.rates?.PKR))?.toFixed(2)}</p>
                                </div>
                            </div>
                            <div>
                                <div className={"p-3"}>
                                    {(render) ?
                                    <CanvasJSChart onRef={ref => chart = ref} containerProps={{ width: '100%', height: '300px' }} options = {options}
                                    />
                                    : null}
                                </div>
                            </div>
                        </a.div>

                    </div>
                </div>
                <div className={"flex-1"}>
                    <div className={"card-wrapper"}>
                        <a.h2 style={springsZoom} className={"text-2xl font-bold mb-5 title"}>Maximize Your
                            Savings With The
                            Best Rates.</a.h2>
                        <p className={"text-sm pb-3"}>Whether it’s Dirhams to PKR, Euro to AED, or USD to AED, we guarantee the best exchange rates.</p>

                        <a.div style={springs2} className={"flex flex-1 relative items-center currency-card  w-inherit"}>
                            <div className={"flex-none country-flag pr-4"}>
                                <img src={"img/australia_flag.png"}/>
                            </div>
                            <div  className={"flex-1"}>
                                <p className={"text-sm mb-2"}>AUD</p>
                                <p className={"text-sm mb-0 mt-0"}>1 AED = {parseFloat(1/(rates[0]?.rates?.AUD))?.toFixed(4)} AUD</p>
                                <p className={"text-sm mb-0 mt-0"}>1 AUD = {rates[0]?.rates?.AUD} AED</p>
                            </div>
                            <div><p className={"px-3 py-1 text-sm cardview"}>{parseFloat(1/(rates[0]?.rates?.AUD))?.toFixed(2)}</p></div>
                        </a.div>
                        <a.div style={springs3} className={"flex flex-1 relative items-center currency-card w-inherit"}>
                            <div className={"flex-none country-flag pr-4"}>
                                <img src={"img/euro_flag.png"}/>
                            </div>
                            <div className={"flex-1"}>
                                <p className={"text-sm mb-2"}>EUR</p>
                                <p className={"text-sm mb-0 mt-0"}>1 AED = {parseFloat(1/(rates[0]?.rates?.EUR))?.toFixed(4)} EUR</p>
                                <p className={"text-sm mb-0 mt-0"}>1 EUR = {rates[0]?.rates?.EUR} AED</p>
                            </div>
                            <div><p className={"px-3 py-1 text-sm cardview"}>{parseFloat(1/(rates[0]?.rates?.EUR))?.toFixed(2)}</p></div>
                        </a.div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Rates;
